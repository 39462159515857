<template>
  <b-card no-body class="bar-chart-card">
    <b-card-body>
      <div class="bar-chart" ref="chart" v-show="showChart"></div>
      <div class="bar-chart" v-show="!showChart">
        <EmptyChart :description="$t('tips_bar')" />
      </div>
      <ChartYearText ref="refChartYearText" v-if="showChart && $parent.$parent.annotations" :text="$parent.$parent.annotations" />
    </b-card-body>
  </b-card>
</template>

<script>
const _ = require('lodash')
import Plotly from 'plotly.js-dist'
import DownloadMixin from '@/mixins/DownloadMixin'
import { CustomLayout } from '@/mixins/ThemeMixin.js'
import EmptyChart from '../../common/EmptyChart.vue'
import ChartYearText from '../../common/ChartYearText.vue'

const LAYOUT = {
  hovermode: 'closest',
  yaxis: { automargin: true },
  xaxis: { automargin: true },
}
const CONFIG = {
  displaylogo: false,
  responsive: true,
  editable: true,
  modeBarButtonsToRemove: ['toImage', 'select2d', 'lasso2d'],
}

export default {
  props: ['chart'],
  mixins: [DownloadMixin, CustomLayout],
  components: { EmptyChart, ChartYearText },
  mounted() {
    this.updateChart()

    // Listen to div resize => chart resize
    new ResizeObserver(() => {
      if (document.getElementsByClassName('bar-chart')[0] && this.$refs.chart && this.$refs.chart.style.display !== 'none') {
        Plotly.Plots.resize(this.$refs.chart)
      }
    }).observe(this.$refs.chart)
  },
  computed: {
    showChart() {
      return this.chart && this.chart.data.length
    },
  },
  watch: {
    chart: {
      deep: true,
      handler() {
        this.updateChart(true)
      },
    },
  },
  methods: {
    updateChart(isUpdate = false) {
      if (isUpdate) {
        let layout = _.merge({}, this.$refs.chart.layout, this.themeLayout, LAYOUT, this.chart.layout)
        Plotly.react(this.$refs.chart, this.chart.data, layout, CONFIG)
      } else {
        let layout = _.merge({}, this.themeLayout, LAYOUT, this.chart.layout)
        Plotly.newPlot(this.$refs.chart, this.chart.data, layout, CONFIG)
      }
    },
  },
}
</script>

<style scoped>
.bar-chart-card .card-body {
  padding-top: 0;
}

.bar-chart {
  height: calc(100vh - 30px - 90.3px - 90px - 2rem - 36px);
}
</style>
