<template>
  <div>
    <b-card class="bar-card custom-card">
      <b-row class="flex-nowrap d-flex align-items-center">
        <b-col cols="auto">
          <b-form-group class="mb-0" style="min-width: 300px">
            <DatasourceSelect v-model="datasourceSelected" mode="multiple" />
          </b-form-group>
        </b-col>
        <b-col cols="auto">
          <b-form-group class="mb-0">
            <RadioGroup :name="`bar-chart-type`" :label="$t('chart_type')" :options="typeList" v-model="typeSelected" />
          </b-form-group>
        </b-col>
        <b-col cols="auto">
          <RadioGroup :name="`bar-scale`" :toggle="true" :label="$t('scale')" :options="scaleChoices" v-model="scale" />
        </b-col>
        <b-col cols="auto" class="ml-auto d-flex align-items-center">
          <SummaryData :chartData="chartData" />
          <Downloader :downloadImage="downloadImage" :downloadHtml="downloadHtml" />
        </b-col>
      </b-row>
    </b-card>

    <b-overlay :show="showOverlay" variant="transparent" opacity="0.85" blur="2px" rounded="sm">
      <bar-chart ref="chart" :chart="chart" />
    </b-overlay>

    <Timeplayer ref="timeplayer" v-model="date" :moveToEnd="moveToEnd" />
  </div>
</template>

<script>
const _ = require('lodash');
import { CHART_COLORS } from '@/constants/colors';
import { splitByLastIndex } from '@/utilities/StringUtility.js';
import PausePlayerMixin from '@/mixins/PausePlayerMixin';
import ChartYearMixin from '@/mixins/ChartYearMixin';
import ScaleMixin from '@/mixins/ScaleMixin';
import SelectDatasourceMixin from '@/mixins/SelectDatasourceMixin';
import { SelectedGroupMixin } from '@/mixins/GroupItemsMixin';
import Timeplayer from '../../common/Timeplayer.vue';
import DatasourceSelect from '../../common/DatasourceSelect/DatasourceSelect.vue';
import Downloader from '../../common/Downloader.vue';
import SummaryData from '../../common/SummaryData.vue';
import RadioGroup from '../../common/RadioGroup.vue';
import BarChart from './BarChartComponent.vue';

const TYPE_LIST = [
  { value: 'group', text: 'Group', icon: require('/static/images/histogram/group.svg').default },
  // { value: 'Normal ', text: 'Normal', icon: require('/static/images/histogram/overlay.svg').default },
  { value: 'stack', text: 'Stack', icon: require('/static/images/histogram/stack.svg').default },
];

export default {
  name: 'bar',
  components: { BarChart, Timeplayer, DatasourceSelect, Downloader, SummaryData, RadioGroup },
  mixins: [SelectDatasourceMixin, ScaleMixin, PausePlayerMixin, SelectedGroupMixin, ChartYearMixin],
  data() {
    return {
      typeList: TYPE_LIST,
      chart: { data: [], layout: { barmode: this.typeSelected } },
      chartData: [],
      moveToEnd: 0,
      showOverlay: false,
      renderDate: [],
    };
  },
  computed: {
    typeSelected: {
      get() {
        return this.$store.state.tabs[this.$options.name].typeSelected;
      },
      set(typeSelected) {
        this.$store.commit(`tabs/SET_${this.$options.name.toUpperCase()}`, { typeSelected });
      },
    },
    date: {
      get() {
        return this.$store.state.tabs[this.$options.name].date;
      },
      set(date) {
        this.$store.commit(`tabs/SET_${this.$options.name.toUpperCase()}`, { date });
      },
    },
    tempProject() {
      return this.$store.state.ecoplot.tempProject;
    },
    selectedGroupbar() {
      return this.$store.state.tabs.bar.selectedGroup;
    },
  },
  watch: {
    selectedGroupbar() {
      this.setChart();
    },
    datasourceSelected(value) {
      // if (value.length !== 0) this.showOverlay = true;
      this.moveToEnd++;
      this.setChart();
    },
    typeSelected() {
      this.setChart();
    },
    // selectedGroup() {
    //   if (this.selectedGroup) this.setChart();
    // },
    scale() {
      this.setChart();
    },
    date() {
      this.setChart();
    },
  },
  methods: {
    async setChart() {
      let traces = [];
      let chartData = [];
      let traceCount = 0;

      // min date and max date (range case and single case)
      const minDate = this.date.length === 2 ? this.date[0] : this.date.length === 1 ? '0000-00-00 00:00:00' : '';
      const maxDate = this.date.length === 2 ? this.date[1] : this.date.length === 1 ? this.date[0] : '';
      //SAVE CURRENT DATE RENDERING
      this.renderDate = [minDate, maxDate];

      // Group by datasource
      const datasourceGrouped = _.groupBy(this.datasourceSelected, (d) => d.datasource);
      for (const datasourceId in datasourceGrouped) {
        const locationsItems = _.map(datasourceGrouped[datasourceId], (data) => {
          return data.location + '-' + data.item;
        });
        const records = await this.selectRangeByLocationsItems(datasourceId, locationsItems, minDate, maxDate, this.scale);
        if (!records.dates) continue;
        if (records && records.dates && records.dates.length !== 0) this.showOverlay = false;
        // Build traces and 'chartData'
        const x = records.dates;
        for (const locationItem of locationsItems) {
          const name = locationItem.replace('-', ' - ');
          const arrObjects = records[locationItem];
          const y = arrObjects ? arrObjects : [];
          traces.push({
            x,
            y,
            type: 'bar',
            name: name,
            marker: {
              color: `${CHART_COLORS.PLOTLY[traceCount % 10]}${this.typeSelected === 'overlay' ? '80' : 'ff'}`,
              line: { width: 1, color: CHART_COLORS.PLOTLY[traceCount % 10] },
            },
          });
          traceCount++;
          chartData = chartData.concat(y);
        }
      }
      this.chart = { data: traces, layout: { barmode: this.typeSelected } };
      this.chartData = chartData;
    },
    downloadHtml() {
      this.$refs.chart.asHtml(this.tempProject.name + '_bar');
    },
    downloadImage() {
      this.$refs.chart.asImage(this.tempProject.name + '_bar');
    },
  },
};
</script>
