export default {
  computed: {
    scale: {
      get() {
        return this.$store.state.tabs[this.$options.name].scale;
      },
      set(scale) {
        this.$store.commit(`tabs/SET_${this.$options.name.toUpperCase()}`, { scale });
      },
    },
    scaleOption: {
      get() {
        return this.$store.state.tabs[this.nameComponent].scale;
      },
      set(scale) {
        this.$store.commit(`tabs/SET_${this.nameComponent.toUpperCase()}`, { scale });
      },
    },
    scaleChoices() {
      return [
        { text: this.$i18n.t('log10'), value: 'log10' },
        { text: this.$i18n.t('log10_x+1'), value: 'log10+' },
        { text: this.$i18n.t('normalize'), value: 'normalize' },
        { text: this.$i18n.t('standardize'), value: 'standardize' },
      ];
    },
  },
};
