import { split } from 'lodash';

export default {
  computed: {
    defaultMaxDecimal() {
      return this.$store.state.settings.defaultMaxDecimal;
    },
    maxDecimal() {
      return this.$store.state.settings.maxDecimal;
    },
    decimalAutoFormat() {
      return this.$store.state.settings.decimalAutoFormat;
    },
    autoMaxNumber() {
      return this.$store.state.settings.autoMaxNumber;
    },
    autoMaxDecimal() {
      return this.$store.state.settings.autoMaxDecimal;
    },
  },
  methods: {
    sortData(data) {
      // data = data.filter(item => !isNaN(item)).map(value => parseFloat(value))
      return [...data].sort((a, b) => a - b);
    },
    getMax(data) {
      if (data && data.length) {
        return data[data.length - 1];
      }
      return +Infinity;
      // return data.reduce((max, p) => ((p === 'NA' ? -Infinity : Number(p)) > max ? Number(p) : max), -Infinity)
    },
    getMin(data) {
      if (data && data.length) {
        return data[0];
      }
      return -Infinity;
      // return data.reduce((min, p) => ((p === 'NA' ? Infinity : Number(p)) < min ? Number(p) : min), Infinity)
    },
    getMean(data) {
      return this.roundToFive(data.reduce((total, p) => total + (p === 'NA' ? 0 : Number(p)), 0) / data.length);
    },
    // prettier-ignore
    getFirstQuantile(data) {
      return this.roundToFive(this.getQuantiles(data).q1)
    },
    // prettier-ignore
    getMedian(data) {
      const mid = Math.floor(data.length / 2)
      let median = data.length % 2 !== 0 ? data[mid] : (data[mid - 1] + data[mid]) / 2
      return this.roundToFive(median)
    },
    // prettier-ignore
    getThirdQuantile(data) {
      return this.roundToFive(this.getQuantiles(data).q3)
    },
    roundToFive(num) {
      return +(Math.round(num + 'e+5') + 'e-5');
    },
    getQuantiles(data) {
      var q1 = null;
      var q3 = null;
      const n = data.length;
      const quotient = Math.floor(n / 4);

      if (quotient >= 1) {
        if (n % 2 == 0 && n) {
          q1 = this.getMedian(data.slice(0, n / 2));
          q3 = this.getMedian(data.slice(n / 2, n));
        } else if (n % 4 == 1) {
          q1 = 0.25 * data[quotient - 1] + 0.75 * data[quotient];
          q3 = 0.75 * data[3 * quotient] + 0.25 * data[3 * quotient + 1];
        } else if (n % 4 == 3) {
          q1 = 0.75 * data[quotient] + 0.25 * data[quotient + 1];
          q3 = 0.25 * data[3 * quotient + 1] + 0.75 * data[3 * quotient + 2];
        }
      }
      return {
        q1: q1,
        q3: q3,
      };
    },
    roundToSameLength(numberArray) {
      numberArray = numberArray.map((number) => {
        if (this.isString(number)) {
          return Number(number.replaceAll(',', ''));
        }
        return number;
      });
      let decimalCount = numberArray.map((number) => {
        if (Math.floor(number) === number) return 0;
        if (number.toString().split('.').length > 1) {
          return number.toString().split('.')[1].length || 0;
        } else {
          return 0;
        }
      });

      let longestDecimal = Math.max(...decimalCount);
      let shortestDecimal = Math.min(...decimalCount);
      let roundDecimal = this.decimalAutoFormat ? Math.ceil((longestDecimal + shortestDecimal) / 2) : this.maxDecimal;

      return numberArray.map((number) => number.toFixed(roundDecimal));
    },
    isString(v) {
      return typeof v === 'string' || v instanceof String;
    },
    isNaValue(v) {
      const naValues = [null, undefined, 'NA', 'NaN', -Infinity, Infinity, ''];
      return naValues.includes(v) || isNaN(v);
    },
    toNumber(n) {
      if (this.isNaValue(n)) return 'NA';
      if (this.isString(n)) {
        return Number(n.replaceAll(',', ''));
      }
      return Number(n);
    },
    roundNumber(n, addComma = true) {
      let strN = String(n);
      n = this.toNumber(n);
      let maxDecimal = this.maxDecimal >= 0 ? this.maxDecimal : this.defaultMaxDecimal;
      if (!this.isNaValue(n) && Number.isFinite(n)) {
        n = n.toFixed(maxDecimal);
      }
      return addComma ? this.numberWithCommas(n) : strN;
    },
    roundNumbers(numbers, addComma = true) {
      return numbers.map((n) => this.roundNumber(n, addComma));
    },
    autoFormatNumbers(numbers, { autoMaxNumber = this.autoMaxNumber, autoMaxDecimal = this.autoMaxDecimal, addComma = false } = {}) {
      if (this.decimalAutoFormat) {
        //  xInt.count , xDec.count
        let xDec = 0;
        let xInt = 0;
        numbers.map((num) => {
          let numSplit = String(num).split('.');
          xInt = xInt < numSplit[0].length ? numSplit[0].length : xInt;
          if (numSplit[1]) {
            xDec = xDec < numSplit[1].length ? numSplit[1].length : xDec;
          }
        });
        xInt = xInt > autoMaxNumber ? autoMaxNumber : xInt;
        xDec = xDec > autoMaxDecimal ? autoMaxDecimal : xDec;
        xDec = xDec > autoMaxNumber - xInt ? autoMaxNumber - xInt : xDec;
        if (xInt < autoMaxNumber) {
          return numbers.map((num) => {
            return addComma ? this.numberWithCommas(Number(num).toFixed(xDec)) : num;
          });
        } else {
          return numbers.map((num) => {
            let numSplit = String(num).split('.');
            let number = `${numSplit[0].length < xInt ? numSplit[0] : numSplit[0].substr(0, xInt - 1)}`;
            return addComma ? this.numberWithCommas(number) : num;
          });
        }
      } else {
        return this.roundNumbers(numbers, addComma);
      }
    },
    numberWithCommas(x) {
      if (this.isNaValue(x)) return 'NA';
      return String(x).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
    },
    autoFormatDuration(arrNumber = [], decimal = { auto: true, default: 3 }) {
      try {
        let arrkq = arrNumber;
        if (arrNumber && arrNumber.length > 0) {
          if (decimal && !decimal.auto && decimal.default > 0) {
            arrkq = arrNumber.map((n) => n.toFixed(decimal));
          } else {
            arrkq = arrNumber.map((n) => {
              let deci = String(n).split('.');
              if (deci[1]) {
                if (deci[1].length < decimal.default) {
                  return n;
                } else {
                  return n.toFixed(decimal.default);
                }
              } else {
                return n;
              }
            });
          }
          return arrkq;
        }
      } catch {
        return arrNumber;
      }
    },
  },
};
